import Rails from 'rails-ujs';
Rails.start();

document.addEventListener('DOMContentLoaded', function() {

  M.AutoInit();

  toast()
  filter_by_select()

  rails_ujs_events()

  var elem = document.querySelector('.autocomplete');
  
  if (document.contains(elem)) {
    autocomplete(elem)
  }


  var check_all = document.getElementById("check_all")

  if (document.contains(check_all)) {
    checkAll(check_all)
  }
  
  
  datePickers()


  link_to_add_fields()

    // var elems = document.querySelectorAll('select');
    // var instances = M.FormSelect.init(elems);
});



function toast() {

  var alert = document.querySelector('.alert');

  if (alert) {
    M.toast({html: alert.textContent, classes: 'red accent-4'})
  }


  var notice = document.querySelector('.notice');

  if (notice) {
    M.toast({html: notice.textContent, classes: 'teal accent-4'})
  }
}



function filter_by_select() {

  var elems = document.querySelectorAll('.filter');
  elems.forEach(function(elem) {

    elem.onchange = function (e) {

      window.location.href = e.target.value
    }
   
  })
}



function rails_ujs_events() {

  document.body.addEventListener('ajax:success', function(event) {
    M.toast({html: "Сохранено", classes: 'teal accent-4'})

    var elem = document.querySelector(':focus');
    elem.blur()
    
  })

  document.body.addEventListener('ajax:error', function(event) {
    M.toast({html: event.detail[0], classes: 'red accent-4'})

    // console.log(event.detail)
  })
}


function autocomplete(elem) {

  var url = elem.getAttribute("data-url") + ".json?query="

  var options = { 
    minLength: 2,
    // onAutocomplete(id) {
    //   window.location.href = "/admin/store_items?wallcovering_id=" + id
    // }
  }

  var instance = M.Autocomplete.init(elem, options);

  elem.onkeyup = function (e) {
    if (elem.value.length >= 2 && e.keyCode != M.keys.ARROW_UP && e.keyCode != M.keys.ARROW_DOWN) { 
      
      var query = elem.value

      Rails.ajax({
        type: "GET", 
        url: url + query,
        success: function(data) { 

          instance.updateData(data);
          instance.open()

        },
        error: function(response){ console.log("err" + response) }
      })
      
    }
  }
}


function datePickers() {
  
  var datePickers = document.querySelectorAll('.datepicker');

  var today = new Date()

  var i18n = { 
    cancel: "Отмена", 
    months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"], 
    monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    weekdaysAbbrev: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]
  }
  
  datePickers.forEach(function(datePicker) {
  
    // var minDate = new Date(datePicker.getAttribute("data-min-date"));

    var datePickerInputDate = datePicker.value

    if (datePickerInputDate) {

      var year = datePickerInputDate.split(".")[2]
      var month = parseInt(datePickerInputDate.split(".")[1]) - 1
      var day = datePickerInputDate.split(".")[0]

      var defaultDate = new Date(year, month, day);
    } else {
      var defaultDate = null
      // var defaultDate = new Date()
    }

    
  
    M.Datepicker.init(datePicker, {
      autoClose: true,
      format: "dd.mm.yyyy",
      firstDay: 1,
      i18n: i18n,
      defaultDate: defaultDate,
      setDefaultDate: true
    });

  })
}


function checkAll(elem) {
  elem.addEventListener("click", function(event) {

    var check_boxes = document.querySelectorAll("input[type='checkbox']")

    check_boxes.forEach(function(elem) {
      elem.checked = event.target.checked
    })

  })
}




// Add fields link

function append_fields(elem) {
  var time = new Date().getTime()
  var regexp = new RegExp(elem.getAttribute("data-id"), "g")
  var new_fieldset = elem.getAttribute("data-fields").replace(regexp, time)
  
  var fields = document.querySelector(".fields_container")
  var div = document.createElement("div")
  div.classList.add("fields")
  div.innerHTML = new_fieldset
  fields.appendChild(div)
  M.updateTextFields()

  remove_fields_init()
  
  // event.preventDefault()  

}

function remove_fields_init() {
  
  var remove_fields_link = document.querySelectorAll(".remove_fields_link")


  remove_fields_link.forEach(function(elem){

    elem.onclick = function(e) {
      e.preventDefault()

      e.target.closest(".fields").classList.add("hidden")

      e.target.parentElement.classList.add("removed")

      e.target.parentElement.previousElementSibling.value = 1

      // check_fields_for_remove()

    }
  })
}


// function check_fields_for_remove(remove_fields_link) {
//   var remove_fields_links = document.querySelectorAll(".remove_fields_link:not(.removed)")

//   // var remove_fields_links_hidden = document.querySelectorAll(".hidden")


//   // remove_fields_links_hidden[0].classList.remove("hidden")

//   if (remove_fields_links.length == 1) {
//     remove_fields_links[0].classList.add("hidden")
//   }

  
// }


function link_to_add_fields() {

  var add_fields_link = document.querySelector(".add_fields_link")
  
  remove_fields_init()
  
  if (document.body.contains(add_fields_link)) {
    add_fields_link.onclick = function (e) {
      var elem = e.target

      if (!elem.classList.contains('add_fields_link')) {
        elem = e.target.parentElement
      }

      append_fields(elem)
    }

    // var add_field_on_enter_input = document.querySelector(".add_field_on_enter")

    // add_field_on_enter_input.addEventListener("keydown", function(e) {
    //   e.preventDefault()
    //   console.log(e)
      
    // })

  }
}






//materialize.js modifications

// 6751
// key: "selectOption",
// value: function selectOption(el) {
//   var text = el.text().trim();

// +++   var id = el[0].getAttribute("data-id")

//   this.el.value = text;
//   this.$el.trigger('change');
//   this._resetAutocomplete();
//   this.close();

//   // Handle onAutocomplete callback.
//   if (typeof this.options.onAutocomplete === 'function') {

// !!!    this.options.onAutocomplete.call(this, id);

//   }
// }


// 6810
// Render
// for (var i = 0; i < matchingData.length; i++) {
//   var _entry = matchingData[i];
//   var $autocompleteOption = $('<li></li>');
//   if (!!_entry.data) {

// +++    $autocompleteOption = $('<li data-id="' + _entry.data + '"></li>');

//     $autocompleteOption.append('<span>' + _entry.key + '</span>');
//   } else {
//     $autocompleteOption.append('<span>' + _entry.key + '</span>');
//   }

//   $(this.container).append($autocompleteOption);
//   this._highlight(val, $autocompleteOption);
// }
